<template>
  <div class="page-title">Lessons</div>
  <div class="lessons">
    <div v-if="lessons.length < 1">
      No data
    </div>
    <div v-else>
      <div v-for="lesson in lessons"
        class="lesson flex"
        @click="selectLesson(lesson)"
        :class="{selected: isSelected(lesson)}"
        :key="lesson.id">
        <div class="col-1">Step {{lesson.step}}</div>
        <div class="col-1">Lesson{{lesson.sort}}</div>
        <div class="col-3">{{lesson.title}}</div>
      </div>
    </div>
  </div>
  <button
    :disabled="!selectedLesson"
    @click="submit"
    class="btn-img mt-2">
    <img src="@/assets/icon-next.png" />
  </button>
</template>

<script>
export default {
  name: 'SelectLessonTest',
  computed: {
    isSelected() {
      return lesson => lesson.id === this.selectedLesson?.id
    }
  },
  data() {
    return {
      lessons: [],
      selectedLesson: null,
    }
  },
  async created() {
    const step = this.$route.query.step
    const forms = this.$route.query.forms
    this.lessons = (await this.$api.get(`/tests?type=lesson&step=${step}&forms=${forms}`)).data.tests
  },
  methods: {
    submit() {
      this.$emit('submit', this.selectedLesson)
    },
    selectLesson(lesson) {
      this.selectedLesson = lesson
    },
  }
}
</script>

<style lang="scss" scoped>

.lessons {
  overflow-y: scroll;
  background: white;
  border-radius: 5px;
  width: 65%;
  margin: auto;
  margin-top: 20px;
  height: 58vh;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  .lesson {
    border-bottom: 1px solid rgb(241, 216, 216);
    padding: 10px 5px;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    padding-left: 20px;
    text-decoration: underline;
    color: #565258;
  }
  .lesson:hover, .selected {
    background: rgb(243, 233, 233);
    cursor: pointer;
  }
}
</style>

