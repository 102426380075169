const mixins = {
  data() {
    return {
      classes: [
        {id: 1, name: '1(A)組'},
        {id: 2, name: '2(B)組'},
        {id: 3, name: '3(C)組'},
        {id: 4, name: '4(D)組'},
        {id: 5, name: '5(E)組'},
        {id: 6, name: '6(F)組'},
        {id: 7, name: '7(G)組'},
        {id: 8, name: '8(H)組'},            
      ],
      allClasses: [
        {id: 1, name: '1年 1(A)組', grade: 1, class: 1},
        {id: 2, name: '1年 2(B)組', grade: 1, class: 2},
        {id: 3, name: '1年 3(C)組', grade: 1, class: 3},
        {id: 4, name: '1年 4(D)組', grade: 1, class: 4},
        {id: 5, name: '1年 5(E)組', grade: 1, class: 5},
        {id: 6, name: '1年 6(F)組', grade: 1, class: 6},
        {id: 7, name: '1年 7(G)組', grade: 1, class: 7},
        {id: 8, name: '1年 8(H)組', grade: 1, class: 8},
        {id: 9, name: '2年 1(A)組', grade: 2, class: 1},
        {id: 10, name: '2年 2(B)組', grade: 2, class: 2},
        {id: 11, name: '2年 3(C)組', grade: 2, class: 3},
        {id: 12, name: '2年 4(D)組', grade: 2, class: 4},
        {id: 13, name: '2年 5(E)組', grade: 2, class: 5},
        {id: 14, name: '2年 6(F)組', grade: 2, class: 6},
        {id: 15, name: '2年 7(G)組', grade: 2, class: 7},
        {id: 16, name: '2年 8(H)組', grade: 2, class: 8},
        {id: 17, name: '3年 1(A)組', grade: 3, class: 1},
        {id: 18, name: '3年 2(B)組', grade: 3, class: 2},
        {id: 19, name: '3年 3(C)組', grade: 3, class: 3},
        {id: 20, name: '3年 4(D)組', grade: 3, class: 4},
        {id: 21, name: '3年 5(E)組', grade: 3, class: 5},
        {id: 22, name: '3年 6(F)組', grade: 3, class: 6},
        {id: 23, name: '3年 7(G)組', grade: 3, class: 7},
        {id: 24, name: '3年 8(H)組', grade: 3, class: 8},
        {id: 25, name: '4年 1(A)組', grade: 4, class: 1},
        {id: 26, name: '4年 2(B)組', grade: 4, class: 2},
        {id: 27, name: '4年 3(C)組', grade: 4, class: 3},
        {id: 28, name: '4年 4(D)組', grade: 4, class: 4},
        {id: 29, name: '4年 5(E)組', grade: 4, class: 5},
        {id: 30, name: '4年 6(F)組', grade: 4, class: 6},
        {id: 31, name: '4年 7(G)組', grade: 4, class: 7},
        {id: 32, name: '4年 8(H)組', grade: 4, class: 8},
        {id: 33, name: '5年 1(A)組', grade: 5, class: 1},
        {id: 34, name: '5年 2(B)組', grade: 5, class: 2},
        {id: 35, name: '5年 3(C)組', grade: 5, class: 3},
        {id: 36, name: '5年 4(D)組', grade: 5, class: 4},
        {id: 37, name: '5年 5(E)組', grade: 5, class: 5},
        {id: 38, name: '5年 6(F)組', grade: 5, class: 6},
        {id: 39, name: '5年 7(G)組', grade: 5, class: 7},
        {id: 40, name: '5年 8(H)組', grade: 5, class: 8},
        {id: 41, name: '6年 1(A)組', grade: 6, class: 1},
        {id: 42, name: '6年 2(B)組', grade: 6, class: 2},
        {id: 43, name: '6年 3(C)組', grade: 6, class: 3},
        {id: 44, name: '6年 4(D)組', grade: 6, class: 4},
        {id: 45, name: '6年 5(E)組', grade: 6, class: 5},
        {id: 46, name: '6年 6(F)組', grade: 6, class: 6},
        {id: 47, name: '6年 7(G)組', grade: 6, class: 7},
        {id: 48, name: '6年 8(H)組', grade: 6, class: 8},                        
      ]
    }
  }
}

export default mixins